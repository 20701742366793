import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/Layouts/Layout';
import SEO from '../components/seo';

export const AboutUs = graphql`
  query About {
    allAboutusJson {
      nodes {
        alt
        details
        id
        title
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

function About({ data }) {
  const { nodes } = data.allAboutusJson;
  return (
    <Layout>
      <SEO title='About' />
      <section className='relative bg-gray-200 pb-40'>
        <div className='container mx-auto px-4 pb-20 pt-32'>
          <div className='items-center flex flex-wrap'>
            <div className='w-full ml-auto px-4'>
              <div className='md:pr-12'>
                <h3 className='text-3xl font-semibold text-orange-500'>
                  WELCOME TO YDS CHICAGO
                </h3>
                <p className='mt-4 text-normal leading-relaxed text-gray-600'>
                  YDS Chicago is guided by H.D.H. Hariprasad Swamiji. The goals
                  of our organization are multi-faceted, all leading towards
                  universal peace and harmony that P.P. Swamiji envisions.
                </p>
                <p className='mt-4 text-normal leading-relaxed text-gray-600'>
                  His vision of spirituality is expressed in His message of
                  ‘Atmiyata’, which is a form of spiritual harmony that
                  transcends the emotions of stubbornness, envy and ego. With
                  His inspiration, guidance, and blessings, millions of
                  individuals today walk the path of spirituality to attain
                  ultimate spiritual salvation.
                </p>
                <p className='mt-4 text-normal leading-relaxed text-gray-600'>
                  H.D.H. Hariprasad Swamiji’s life slogan, “Yuvako maru sarvasva
                  chhe” – Youth is my life, signifies the special place He has
                  for Youth in His heart. He always emphasizes the extraordinary
                  potential present in a youth: “Yuvan dhare tevo thai shake” –
                  A youth has the potential to become what he chooses to become.
                  Through His selfless love, compassionate care and divine
                  blessings, He has given the modern youth a new meaning and
                  direction in life, with a special emphasis on keeping the
                  right company and achieving excellence in all aspects of life.
                </p>
                <p className='mt-4 text-normal leading-relaxed text-gray-600'>
                  This website is created to give you a small glimpse into
                  Swamiji’s life’s work and to spread his message of harmony.
                  This site is also the means by which we can provide
                  information about regular sabha’s as well as special events to
                  those living in the greater Chicagoland area.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='justify-center text-center flex flex-wrap'>
          <div className='w-full md:w-6/12 px-12 md:px-4'>
            <h2 className='font-semibold text-4xl text-orange-500'>
              Who Are We?
            </h2>
          </div>
        </div>
      </section>

      <section className='block relative z-1 bg-gray-700 pb-40'>
        <div className='container mx-auto'>
          <div className='justify-center flex flex-wrap'>
            <div className='w-full lg:w-12/12 px-4  -mt-24'>
              <div className='flex flex-wrap'>
                {nodes.map(({ id, title, src, alt, details }) => (
                  <div className='w-full lg:w-4/12 px-4' key={id}>
                    <h5 className='text-xl font-semibold pb-4 text-center text-orange-500'>
                      {title}
                    </h5>
                    <div className='hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150'>
                      <GatsbyImage
                        image={src.childImageSharp.gatsbyImageData}
                        alt={alt}
                        className='align-middle border-none max-w-full h-auto rounded-lg'
                      />
                    </div>
                    <p className='mb-4 text-white'>{details}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

About.propTypes = {
  data: PropTypes.object,
};

export default About;
